import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'terra',
});

export const environmentRouteRef = createRouteRef({
  id: 'terra-environment',
  params: ['namespace', 'name']
})

export const createEnvironmentRouteRef = createRouteRef({
  id: 'terra-create-environment',
  params: ['namespace']
})

export const appRouteRef = createRouteRef({
  id: 'terra-app',
  params: ['namespace', 'env', 'appTemplate']
})