import { ConditionStatus } from "./terra-environment"
import { TerraApp } from "./terra-app"
import { HelmParam } from "./HelmParam"
import { AppTemplate } from "./appTemplate"


export type AppWrite = {
    config: {
        [name: string]: HelmParam
    }
}

export type App = AppWrite & {
    name: string
    environment: string
    appTemplate: string

    // Status
    conditions: {
        'Created': ConditionStatus,
        'ArgoCDAppCreated': ConditionStatus,
        'ArgoCDAppSynced': ConditionStatus,
        'ArgoCDHealthy': ConditionStatus,
        'Sleeping': ConditionStatus,
    }
    phase: 'Unknown' | 'Progressing' | 'Running' | ''
    argoSyncStatus: 'Unknown' | 'Synced' | 'OutOfSync' | ''
    argoHealthStatus: 'Unknown' | 'Progressing' | 'Healthy' | 'Suspended' | 'Degraded' | 'Missing' | ''

    terraNamespace: string
}

export type AppWithTemplate = {
    app: App,
    appTemplate: AppTemplate,
}

export function fromTerraApp(terraApp: TerraApp, terraNamespace: string): App {
    return {
        name: terraApp.name,
        terraNamespace: terraNamespace,
        environment: terraApp.environment,
        appTemplate: terraApp.appTemplate,
        conditions: terraApp.conditions,
        phase: terraApp.phase,
        argoSyncStatus: terraApp.argoSyncStatus,
        argoHealthStatus: terraApp.argoHealthStatus,
        config: terraApp.config,
    }
}