import { BackstageOverrides } from '@backstage/core-components';
import { BackstageOverrides as CatalogReactOverrides } from '@backstage/plugin-catalog-react';
import { BackstageTheme, createTheme, lightTheme } from '@backstage/theme';

const baseTheme = createTheme({
    palette: {
      ...lightTheme.palette,
      primary: {
        main: '#0052CC',
        light: '#4C9AFF',
        dark: '#172B4D',
      },
      /*secondary: {
        main: '#FF5630',
        light: '#FFAB00',
        dark: '#6554C0',
      },*/
      navigation: {
        ...lightTheme.palette.navigation,
        background: '#172B4D',
        color: '#FFFFFF',
        indicator: '#2684FF',
        navItem: {
          hoverBackground: 'rgba(116,118,121,0.6)',
        },
      },
    },
    fontFamily: 'Roboto, sans-serif',
    defaultPageTheme: 'home',
});

const createCustomThemeOverrides = (
  theme: BackstageTheme,
): BackstageOverrides & CatalogReactOverrides => {
  return {
    BackstageHeader: {
      header: {
        backgroundImage: 'unset',
        //backgroundColor: theme.palette.secondary.light,
      },
      title: {
        color: theme.palette.primary.dark,
        fontWeight: 900,
      },
      subtitle: {
        color: theme.palette.primary.dark,
      },
      type: {
        color: theme.palette.primary.dark,
      },
    },
    BackstageHeaderLabel: {
      root: {
        color: theme.palette.primary.dark,
      },
      label: {
        color: theme.palette.primary.dark,
      },
      value: {
        color: theme.palette.primary.main,
      }
    }
  };
};

export const atlasTheme: BackstageTheme = {
  ...baseTheme,
  overrides: {
    ...baseTheme.overrides,
    ...createCustomThemeOverrides(baseTheme),
  },
};
