import { createApiRef, IdentityApi } from "@backstage/core-plugin-api";
import { App, AppWithTemplate, AppWrite, fromTerraApp } from "./app";
import { AppTemplate, fromTerraAppTemplate } from "./appTemplate";
import { Environment, fromTerraEnvironment, EnvironmentWithApps, EnvironmentWrite } from "./environment";
import { ActionJob, ActionParameter, ActionType } from "./terra-action-type";
import { TerraApp } from "./terra-app";
import { TerraAppTemplate } from "./terra-app-template";
import { TerraEnvironment, TerraEnvironmentWithApps } from "./terra-environment";

export const terraApiRef = createApiRef<TerraAPI>({
  id: 'plugin.terra',
});

export interface TerraAPI {
  getNamespace(): string
  listEnvironments(): Promise<ResponseOrError<Environment[]>>
  getEnvironment(name: string): Promise<ResponseOrError<Environment>>
  getEnvironmentWithApps(name: string, page: number, perPage: number): Promise<ResponseOrError<EnvironmentWithApps>>
  deleteEnvironment(name: string): Promise<ResponseOrError<Environment>>
  updateEnvironment(name: string, environment: EnvironmentWrite): Promise<ResponseOrError<Environment>>
  createEnvironment(name: string, environment: EnvironmentWrite): Promise<ResponseOrError<Environment>>
  getApp(name: string): Promise<ResponseOrError<App>>
  updateApp(name: string, app: AppWrite): Promise<ResponseOrError<App>>
  getAppWithTemplate(name: string): Promise<ResponseOrError<AppWithTemplate>>
  listAppTemplates(): Promise<ResponseOrError<AppTemplate[]>>
  getAppTemplate(name: string): Promise<ResponseOrError<AppTemplate>>
  getActionTypes(): Promise<ResponseOrError<ActionType[]>>
  runAction(environment: string, action: string, parameters: ActionParameter[]): Promise<ResponseOrError<{}>>
  getActionRuns(environment: string): Promise<ResponseOrError<{actions: ActionJob[]}>>
}

export interface ErrorMessage {
  message: string
}

export function isError(object: any): object is ErrorMessage {
  return 'message' in object;
}

export type ResponseOrError<T> = ErrorMessage | T

export type Options = {
  backendBaseUrl: string;
  namespace: string,
  identityApi: IdentityApi;
};

export class TerraClient implements TerraAPI {
  private readonly backendBaseUrl: string;
  private readonly identityApi: IdentityApi;
  private readonly namespace: string;

  constructor(options: Options) {
    this.backendBaseUrl = options.backendBaseUrl
    this.identityApi = options.identityApi
    this.namespace = options.namespace;
  }

  private getUrl() {
    return `${this.backendBaseUrl}/api/proxy/terra/${this.namespace}`
  }

  private async fetchDecode(url: string) {
    const { token } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: token
        ? {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
        : undefined,
    })
    return response
  }

  private async putDecode(url: string, data: any) {
    const { token } = await this.identityApi.getCredentials();
    const headers = {'Content-Type': 'application/json'}
    const response = await fetch(url, {
      method: 'PUT',
      headers: token
        ? {...headers,
          Authorization: `Bearer ${token}`,
        }
        : headers,
      body: JSON.stringify(data)
    })
    return response
  }

  private async postDecode(url: string, data: any) {
    const { token } = await this.identityApi.getCredentials();
    const headers = {'Content-Type': 'application/json'}
    const response = await fetch(url, {
      method: 'POST',
      headers: token
        ? {...headers,
          Authorization: `Bearer ${token}`,
        }
        : headers,
      body: JSON.stringify(data)
    })
    return response
  }

  private async deleteDecode(url: string) {
    const { token } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      method: 'DELETE',
      headers: token
        ? {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
        : undefined,
    })
    return response
  }

  getNamespace(): string {
    return this.namespace
  }

  async listEnvironments(): Promise<ResponseOrError<Environment[]>> {
    const url = `${this.getUrl()}/environment`
    const response = await this.fetchDecode(url)
    const environments: ResponseOrError<{environments: TerraEnvironment[]}> = await response.json()
    if (isError(environments)) {
      return environments
    }
    return environments.environments.map(env => fromTerraEnvironment(env, this.namespace))
  }

  async getEnvironment(name: string): Promise<ResponseOrError<Environment>> {
    const url = `${this.getUrl()}/environment/${name}`
    const response = await this.fetchDecode(url)
    const environment: ResponseOrError<TerraEnvironment> = await response.json()
    if (isError(environment)) {
      return environment
    }
    return fromTerraEnvironment(environment, this.namespace)
  }

  async getEnvironmentWithApps(name: string, page: number, perPage: number): Promise<ResponseOrError<EnvironmentWithApps>> {
    const url = `${this.getUrl()}/environment/${name}/app?page=${page}&per_page=${perPage}`
    const response = await this.fetchDecode(url)
    const environment: ResponseOrError<TerraEnvironmentWithApps> = await response.json()
    if (isError(environment)) {
      return environment
    }
    const env = fromTerraEnvironment(environment.environment, this.namespace)
    const apps = environment.apps.map((app) => fromTerraApp(app, this.namespace))
    return {
      environment: env,
      apps: apps,
      appsCount: environment.appsCount,
      page: environment.page,
      pageSize: environment.pageSize
    }
  }

  async deleteEnvironment(name: string): Promise<ResponseOrError<Environment>> {
    const url = `${this.getUrl()}/environment/${name}`
    const response = await this.deleteDecode(url)
    const environment: ResponseOrError<TerraEnvironment> = await response.json()
    if (isError(environment)) {
      return environment
    }
    return fromTerraEnvironment(environment, this.namespace)
  }

  async updateEnvironment(name: string, inputEnvironment: EnvironmentWrite): Promise<ResponseOrError<Environment>> {
    const url = `${this.getUrl()}/environment/${name}`
    const response = await this.putDecode(url, inputEnvironment)
    const environment: ResponseOrError<TerraEnvironment> = await response.json()
    if (isError(environment)) {
      return environment
    }
    return fromTerraEnvironment(environment, this.namespace)
  }

  async createEnvironment(name: string, inputEnvironment: EnvironmentWrite): Promise<ResponseOrError<Environment>> {
    const url = `${this.getUrl()}/environment/${name}`
    const response = await this.postDecode(url, inputEnvironment)
    const environment: ResponseOrError<TerraEnvironment> = await response.json()
    if (isError(environment)) {
      return environment
    }
    return fromTerraEnvironment(environment, this.namespace)
  }

  async getApp(name: string): Promise<ResponseOrError<App>> {
    const url = `${this.getUrl()}/app/${name}`
    const response = await this.fetchDecode(url)
    const app: ResponseOrError<TerraApp> = await response.json()
    if (isError(app)) {
      return app
    }
    return fromTerraApp(app, this.namespace)
  }
  
  async updateApp(name: string, appWrite: AppWrite): Promise<ResponseOrError<App>> {
    const url = `${this.getUrl()}/app/${name}`
    const response = await this.putDecode(url, appWrite)
    const app: ResponseOrError<TerraApp> = await response.json()
    if (isError(app)) {
      return app
    }
    return fromTerraApp(app, this.namespace)
  }

  async getAppWithTemplate(name: string): Promise<ResponseOrError<AppWithTemplate>> {
    const url = `${this.getUrl()}/app/${name}/app-template`
    const response = await this.fetchDecode(url)
    const appWithTemplate: ResponseOrError<{app: TerraApp, appTemplate: TerraAppTemplate}> = await response.json()
    if (isError(appWithTemplate)) {
      return appWithTemplate
    }
    return {
      app: fromTerraApp(appWithTemplate.app, this.namespace),
      appTemplate: fromTerraAppTemplate(appWithTemplate.appTemplate, this.namespace),
    }
  }

  async listAppTemplates(): Promise<ResponseOrError<AppTemplate[]>> {
    const url = `${this.getUrl()}/app-template`
    const response = await this.fetchDecode(url)
    const appTemplates: ResponseOrError<{appTemplates: TerraAppTemplate[]}> = await response.json()
    if (isError(appTemplates)) {
      return appTemplates
    }
    return appTemplates.appTemplates.map(terraAppTemplate => fromTerraAppTemplate(terraAppTemplate, this.namespace))
  }

  async getAppTemplate(name: string): Promise<ResponseOrError<AppTemplate>> {
    const url = `${this.getUrl()}/app-template/${name}`
    const response = await this.fetchDecode(url)
    const appTemplate: ResponseOrError<TerraAppTemplate> = await response.json()
    if (isError(appTemplate)) {
      return appTemplate
    }
    return fromTerraAppTemplate(appTemplate, this.namespace)
  }

  async getActionTypes(): Promise<ResponseOrError<ActionType[]>> {
    const url = `${this.getUrl()}/action-type`
    const response = await this.fetchDecode(url)
    return await response.json()
  }

  async runAction(environment: string, action: string, parameters: ActionParameter[]): Promise<ResponseOrError<{}>> {
    const url = `${this.getUrl()}/environment/${environment}/action/${action}`
    const response = await this.postDecode(url, {"parameters": parameters})
    return await response.json()
  }

  async getActionRuns(environment: string): Promise<ResponseOrError<{actions: ActionJob[]}>> {
    const url = `${this.getUrl()}/environment/${environment}/action`
    const response = await this.fetchDecode(url)
    return await response.json()
  }
}
