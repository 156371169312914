import React from 'react';
import Icon from './LogoFull.svg';

const LogoFull = () => {
  return (
    <img src={Icon} alt="Atlas Logo" width={121} height={128} />
  );
};

export default LogoFull;
