import { configApiRef, createApiFactory, createPlugin, createRoutableExtension, identityApiRef } from '@backstage/core-plugin-api';
import { terraApiRef, TerraClient } from './api';

import { createEnvironmentRouteRef, environmentRouteRef, rootRouteRef } from './routes';

export const terraPlugin = createPlugin({
  id: 'terra',
  apis: [
    createApiFactory({
      api: terraApiRef,
      deps: {
        identityApi: identityApiRef,
        configApi: configApiRef,
      },
      factory: ({ identityApi, configApi }) =>
        new TerraClient({
          identityApi,
          backendBaseUrl: configApi.getString('backend.baseUrl'),
          namespace: "terra",
        }),
    }),
  ],
  routes: {
    root: rootRouteRef,
    environment: environmentRouteRef,
  },
});

export const TerraPage = terraPlugin.provide(
  createRoutableExtension({
    name: 'Terra Environments',
    component: () =>
      import('./components/TerraPage').then(m => m.TerraPage),
    mountPoint: rootRouteRef,
  }),
);

export const EnvironmentHomePage = terraPlugin.provide(
  createRoutableExtension({
    name: 'Terra Environment',
    component: () =>
      import('./components/EnvironmentPage').then(m => m.EnvironmentPage),
    mountPoint: environmentRouteRef,
  }),
);

export const TerraCreatePage = terraPlugin.provide(
  createRoutableExtension({
    name: 'Create Terra Environment',
    component: () =>
      import('./components/CreateEnvironmentPage').then(m => m.CreateEnvironmentPage),
    mountPoint: createEnvironmentRouteRef,
  }),
)

export const TerraAppPage = terraPlugin.provide(
  createRoutableExtension({
    name: 'Terra App',
    component: () =>
      import('./components/App').then(m => m.AppPage),
    mountPoint: createEnvironmentRouteRef,
  }),
)