import { App } from "./app"
import { AppStatus, ConditionStatus, TerraEnvironment } from "./terra-environment"

export type EnvironmentWrite = {
  appGroups: string[]
  appsAdded: string[]
  appsRemoved: string[]
  global: { [name: string]: string }
  sleeping: boolean
}

export type Environment = EnvironmentWrite & {
  name: string
  terraNamespace: string
  conditions: {
    'Creating': ConditionStatus,
    'Ready': ConditionStatus,
  }
  appStatus: {
    [name: string]: AppStatus,
  }
}

export type EnvironmentWithApps = {
  environment: Environment
  apps: App[]
  appsCount: number
  page: number
  pageSize: number
}


export function fromTerraEnvironment(terraEnv: TerraEnvironment, terraNamespace: string): Environment {
  return {
    name: terraEnv.name,
    terraNamespace: terraNamespace,
    appGroups: terraEnv.appGroups,
    appsAdded: terraEnv.appsAdded,
    appsRemoved: terraEnv.appsRemoved,
    global: terraEnv.global,
    conditions: terraEnv.conditions,
    appStatus: terraEnv.appStatus,
    sleeping: terraEnv.sleeping
  }
}

/**
 * getAppCount returns number of apps in created, queued and deleting state.
 * @param env 
 * @returns createdCount, queuedCount, deletingCount
 */
export function getAppCount(env: Environment): [number, number, number] {
  var created = 0;
  var queued = 0;
  var deleting = 0;
  for (const appName in env.appStatus) {
    switch (env.appStatus[appName]) {
      case 'Queued':
        queued++
        break;
      case 'Created':
        created++
        break;
      default:
        deleting++
    }
  }
  return [created, queued, deleting]
}