import { HelmConfiguration, TerraAppTemplate } from "./terra-app-template"

export type AppTemplate = {
  name: string,
  namespaceOverride?: string,
  gitRepository: string, 
  path: string,
  priority: number,
  helmConfiguration: HelmConfiguration,

  terraNamespace: string,
}

export function fromTerraAppTemplate(appTemplate: TerraAppTemplate, terraNamespace: string): AppTemplate {
  return {
    name: appTemplate.name,
    namespaceOverride: appTemplate.namespaceOverride,
    gitRepository: appTemplate.gitRepository,
    path: appTemplate.path,
    priority: appTemplate.priority,
    helmConfiguration: appTemplate.helmConfiguration,

    terraNamespace: terraNamespace,
  }
}